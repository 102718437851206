export function showNavbar() {
    var sidebar = document.getElementById("sidebar");
    var overlay = document.getElementById("sidebar-overlay");
    sidebar.classList.toggle("show");
    overlay.classList.toggle("show");
}

export function showSearchbar() {
    var element = document.getElementById("searchbar");
    element.classList.toggle("show");
}
