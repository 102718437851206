import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../apis/ApiService';
import Navigation from '../Navigation';
import Header from '../Header';
import LoadingSpinner from '../common/LoadingSpinner';


const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            
            try {
                const response = await apiServiceInstance.getTransactions(); // Fetching transaction data
                setTransactions(response.data); // Assuming response.data contains the transaction list
            } catch (err) {
                //setError(err.message || 'Failed to fetch transactions. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                {loading && (
                    <LoadingSpinner />
                )}

                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2>Transaction History</h2>
                    {transactions.length === 0 ? (
                        <div>No transactions found.</div>
                    ) : (
                        <div className="row">
                            {transactions.map((transaction) => (
                                <div className="col-md-4 mb-4" key={transaction.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Transaction ID : {transaction.id}</h5>
                                            <p className="card-text">
                                                <strong>Amount : </strong> {transaction.amount}<br />
                                                <strong>Type : </strong> 
                                                <span style={{ color: transaction.type === 'credit' ? 'green' : transaction.type === 'debit' ? 'red' : 'black' }}>
                                                    {transaction.type.toUpperCase()}
                                                </span><br />
                                                <strong>Updated Balance : </strong> {transaction.updated_balance}<br />
                                                <strong>Remarks : </strong> {transaction.remarks}<br />
                                                <strong>Transaction Date : </strong> {new Date(transaction.created_at).toLocaleString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default TransactionHistory;
