// BidInputField.js
import React from 'react';
import { Form, Col } from 'react-bootstrap';

const BidInputField = ({ index, digit, amount, handleAmountChange, inputHeight }) => {
    return (
        <Col xs={6} key={index} style={{ position: 'relative', display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Form.Group controlId={`bid-${index}`} style={{ width: '100%' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: inputHeight,
                        backgroundColor: 'var(--primary-color)',
                        color: 'white',
                        padding: '0 10px',
                        borderRadius: '8px 0 0 8px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.9rem',
                        lineHeight: '1',
                        zIndex: '1',
                    }}
                >
                    {digit}
                </div>
                <Form.Control
                    type="number"
                    value={amount}
                    onChange={(e) => handleAmountChange(index, e.target.value)}
                    min={10}
                    placeholder="Amount"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        borderColor: 'darkgray',
                        paddingLeft: '45px',
                        height: inputHeight,
                        fontSize: '1rem' 
                    }}
                />
            </Form.Group>
        </Col>
    );
};

export default BidInputField;
