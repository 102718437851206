import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Header from '../../Header';
import Footer from '../../Footer';
import Navigation from '../../Navigation';

// Import your SVG icons
import SingleDigitIcon from '../../assets/icons/single_digit.svg';
import JodiIcon from '../../assets/icons/jodi.svg';
import SinglePanelIcon from '../../assets/icons/single_panel.svg';
import DoublePanelIcon from '../../assets/icons/double_panel.svg';
import TriplePanelIcon from '../../assets/icons/triple_panel.svg';
import HalfSangamIcon from '../../assets/icons/half_sangam.svg';
import Games from '../../constants/Games';
//import FullSangamIcon from '../../assets/icons/full_sangam.svg';

export default function GameType() {
    const location = useLocation();
    const navigate = useNavigate(); 
    const game = location.state?.game;

    useEffect(() => {
        // Redirect to home if game or game_type is missing
        if (!game) {
            navigate('/home');
        }
    }, [game, navigate]);

    const gameTypes = [
        {game_type_id: Games.SINGLE_DIGIT, name: 'Single Digit', icon: SingleDigitIcon, slug : 'single_digit' },
        {game_type_id: Games.JODI, name: 'Jodi', icon: JodiIcon, slug : 'jodi' },
        {game_type_id: Games.SINGLE_PANEL, name: 'Single Panel', icon: SinglePanelIcon , slug : 'single_panna'},
        {game_type_id: Games.DOUBLE_PANEL, name: 'Double Panel', icon: DoublePanelIcon, slug : 'double_panna' },
        {game_type_id: Games.TRIPLE_PANEL, name: 'Triple Panel', icon: TriplePanelIcon , slug : 'tripple_panna'},
        {game_type_id: Games.BULK_JODI, name: 'Bulk Jodi', icon: HalfSangamIcon, slug : 'jodi' },
        // { name: 'Half Sangam', icon: HalfSangamIcon, slug : 'half_sangam' },
        // { name: 'Full Sangam', icon: FullSangamIcon , slug : 'full_sangam'},
    ];

    const ButtonContainer = styled('div')({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
        marginTop: '20px'
    });

    const GameButton = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100px',
    });

    const CircularButton = styled(IconButton)({
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        width: '80px',
        height: '80px',
        '&:hover': {
            backgroundColor: '#e0e0e0'
        }
    });

    const handleButtonClick = (gameType) => {
        navigate('/place-bid', { state: { gameType, game } });
    };
   
    const tintColor = 'rgba(250, 176, 41, 1)';
    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <div>
                    {/* Start: Game Type List */}
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        {game ? (
                            <div>
                                <h2>{game.title}</h2>
                                <p>Select Bidding Option</p>
                            </div>
                        ) : (
                            <p>No game selected.</p>
                        )}

                        {/* Button Grid */}
                        <ButtonContainer>
                            {gameTypes.map((gameType, index) => (
                                <GameButton key={index}>
                                    <CircularButton onClick={() => handleButtonClick(gameType)}>
                                        <img 
                                            src={gameType.icon} 
                                            alt={gameType.name} 
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                filter: `drop-shadow(0 0 0 ${tintColor})` // Apply tint using filter
                                            }} 
                                        />
                                    </CircularButton>
                                    <Typography variant="subtitle1">{gameType.name}</Typography>
                                </GameButton>
                            ))}
                        </ButtonContainer>
                    </div>
                    {/* End: Game Type List */}
                </div>
                <Footer />
            </main>
        </div>
    );
}
