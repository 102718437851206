// BidHistoryMain.js
import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../../apis/ApiService';
import Header from '../../Header';
import Navigation from '../../Navigation';
import LoadingSpinner from '../../common/LoadingSpinner';

const BidHistoryMain = () => {
    const [bids, setBids] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBids = async () => {
            setLoading(true);

            try {
                const response = await apiServiceInstance.getBidsMain(); // Ensure this method matches your ApiService
                setBids(response.data); // Assuming response.data contains the bid list
            } catch (err) {

            } finally {
                setLoading(false);
            }
        };

        fetchBids();
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2>Bid History</h2>
                    {loading && <LoadingSpinner />}
                    {bids.length === 0 ? (
                        <div>No bids found.</div>
                    ) : (
                        <div className="row">
                            {bids.map((bid) => (
                                <div className="col-md-4 mb-4" key={bid.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Bid ID : {bid.id}</h5>
                                            <p className="card-text">
                                                <strong>{bid.game_name} - {bid.play_type.toUpperCase()}</strong><br />
                                                <strong>Bid Amount : </strong> {bid.amount}<br />
                                                <strong>Bid Date : </strong> {new Date(bid.bid_date).toLocaleString()}<br />
                                                <strong>Status : </strong> 
                                                <span style={{
                                                    color: bid.status === '0' ? 'orange' :
                                                        bid.status === '1' ? 'green' :
                                                        bid.status === '2' ? 'red' :
                                                        bid.status === '3' ? 'gray' :
                                                        'black'  // Default color for unknown status
                                                }}>
                                                    {bid.status === '0' ? 'Pending' :
                                                    bid.status === '1' ? 'Win' :
                                                    bid.status === '2' ? 'Loose' :
                                                    bid.status === '3' ? 'Cancelled' :
                                                    'Unknown'}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                    )}
                </div>
            </main>
        </div>
    );
};

export default BidHistoryMain;
