import React, { useState } from 'react';
import { Button, TextField, Stack, CardContent, Typography } from '@mui/material';
import ApiService from '../apis/ApiService'; // Adjust the import according to your file structure
import Navigation from '../Navigation';
import Header from '../Header';
import { Card } from 'react-bootstrap';
import LoadingSpinner from '../common/LoadingSpinner';
import SubmitButton from '../common/SubmitButton';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddFund = () => {
    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [screenshot, setScreenshot] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state

    const handleAmountChange = (value) => {
        setAmount(value);
    };

    const handleFileChange = (event) => {
        setScreenshot(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Start loading

        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('remarks', remarks);
        formData.append('request_type', 'UPI');
        formData.append('image', screenshot);

        try {
            const response = await ApiService.submitFundRequest(formData);
            // Check response status and show appropriate message
            if (response.status) {
                toast.success(response.message || 'Fund request submitted successfully!');
                // Clear the form fields
                setAmount('');
                setRemarks('');
                setScreenshot(null);
            } else {
                toast.error(response.message || 'Failed to submit fund request.');
            }
        } catch (error) {
            // Show error message if API call fails
            toast.error('Error submitting fund request: ' + (error.response?.data?.message || error.message));
            console.error('Error submitting fund request:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div>
            <ToastContainer /> {/* Toastify Container for notifications */}
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Add Fund
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Transfer your payment on our UPI ID: [UPI ID] and submit the below details
                            </Typography>

                            <TextField
                                label="Amount"
                                variant="outlined"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            {/* Amount Buttons */}
                            <Stack direction="row" spacing={2} marginBottom={2}>
                                {[500, 1000, 5000, 10000].map((value) => (
                                    <Button
                                        key={value}
                                        variant="contained"
                                        onClick={() => handleAmountChange(value)}
                                        style={{ flex: 1 }}
                                    >
                                        ₹{value}
                                    </Button>
                                ))}
                            </Stack>

                            <TextField
                                label="Remarks"
                                variant="outlined"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <Typography variant="subtitle1" style={{ marginTop: '20px', textAlign: 'left' }}>
                                Please choose your screenshot
                            </Typography>

                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ margin: '10px 0', overflow: 'hidden', width: '100%' }}
                            />
                            {screenshot && (
                                <Typography variant="body2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {screenshot.name}
                                </Typography>
                            )}

                            {loading ? <LoadingSpinner /> : <SubmitButton onClick={handleSubmit} label="Submit" />}
                        </CardContent>
                    </Card>
                </div>
            </main>
        </div>
    );
};

export default AddFund;
