import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle'; // Add Fund icon
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'; // Withdraw Fund icon

const FundButtons = () => {
    const navigate = useNavigate();

    const handleAddFund = () => {
        navigate('/add-fund');
    };

    const handleWithdrawFund = () => {
        navigate('/withdraw-fund');
    };

    return (
        <Stack direction="row" spacing={2} style={{ width: '100%' }}>
            <Button 
                variant="contained" 
                style={{ backgroundColor: 'var(--primary-color)', color: 'white', flex: 1 }} 
                startIcon={<AddCircleIcon />} 
                onClick={handleAddFund}
            >
                Add Fund
            </Button>
            <Button 
                variant="outlined" 
                style={{ 
                    color: 'var(--primary-color)', 
                    borderColor: 'var(--primary-color)', 
                    backgroundColor: 'white',
                    flex: 1,
                    whiteSpace: 'nowrap' // Prevent text from wrapping
                }} 
                startIcon={<AccountBalanceWalletIcon />} 
                onClick={handleWithdrawFund}
            >
                Withdraw Fund
            </Button>
        </Stack>
    );
};

export default FundButtons;
