// src/pages/Notifications.jsx

import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../apis/ApiService';
import Navigation from '../Navigation';
import Header from '../Header';
import LoadingSpinner from '../common/LoadingSpinner';
import config from '../config';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const response = await apiServiceInstance.getNotifications(); // Call the API function
                setNotifications(response.data); // Assume 'data' contains the list of notifications
            } catch (err) {
                console.error("Error fetching notifications:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                {loading && <LoadingSpinner />}
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2>Notifications</h2>
                    {notifications.length === 0 ? (
                        <div>No notifications found.</div>
                    ) : (
                        <div className="row">
                            {notifications.map((notification) => (
                                <div className="col-md-4 mb-4" key={notification.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{notification.title}</h5>
                                            <p className="card-text">
                                                <strong>Description:</strong> {notification.description}<br />
                                                <strong>Date:</strong> {new Date(notification.created_at).toLocaleString()}
                                            </p>
                                            {notification.image && (
                                                <img
                                                    src={config.baseImageUrl+`notifications/${notification.image}`} // Update to the correct image path
                                                    alt={notification.title}
                                                    className="img-fluid mt-2"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default Notifications;
