import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitHelper from '../../../helper/DigitHelper';
import BidInputField from '../components/BidInputField';

const TripplePanna = ({ handleAddBid }) => {
    const listTriplePatti = DigitHelper.getTripplePatti(); // Get list from DigitHelper
    const [amounts, setAmounts] = useState(Array(listTriplePatti.length).fill('')); // Initialize amounts array

    const handleAmountChange = (index, amount) => {
        const updatedAmounts = [...amounts];
        updatedAmounts[index] = amount;
        setAmounts(updatedAmounts);
    };

    const handleAddTripplePannaBid = () => {
        const newBids = listTriplePatti.map((number, index) => (
            amounts[index] ? { digit: number, amount: parseInt(amounts[index]) } : null
        )).filter(bid => bid); // Filter out null values

        handleAddBid(newBids);
        setAmounts(Array(listTriplePatti.length).fill('')); // Clear inputs after adding
    };

    return (
        <div>
            {/* Display matching triple patti numbers and amount inputs */}
            <Row className="mb-3 ms-1">
                {listTriplePatti.map((number, index) => (
                    <BidInputField
                        key={number}
                        index={index}
                        digit={number}
                        amount={amounts[index]}
                        handleAmountChange={handleAmountChange}
                        inputHeight='40px' // You can set this based on your design preference
                    />
                ))}
            </Row>

            {/* Add button */}
            <Button className="w-100" style={{ backgroundColor: 'var(--primary-color)', color: 'white' }} onClick={handleAddTripplePannaBid}>
                Add
            </Button>
        </div>
    );
};

export default TripplePanna;
