import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import Navigation from '../Navigation';
import Header from '../Header';
import Footer from '../Footer';
import ApiService from '../apis/ApiService';
import { CircularProgress } from '@mui/material';
import FundButtons from '../common/FundButtons';
import ContactButtons from '../common/ContactButtons';
import StarlineGames from '../common/StarlineGames';

export default function Home() {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();  // Initialize navigate hook

    useEffect(() => {
        async function fetchGames() {
            try {
                const response = await ApiService.getGames();
                setGames(response.data);
            } catch (error) {
                console.error('Failed to fetch games:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchGames();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    // Handler to navigate to GameType page with selected game details
    const handlePlayClick = (game) => {
        navigate('/game-type', { state: { game } });
    };

    const handleStartlineGamesClick = () => {
        navigate('/startline-games');
    };

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <div className="mt-4 px-3 px-xl-0">
                    <FundButtons />
                    <ContactButtons/>
                    {/* Start: Startline Games Card */}
                    {/* <StarlineGames/> */}
                    {/* <div className="card mb-4 mt-4">
                        <div className="card-body d-flex justify-content-between align-items-center p-2"> 
                            <h4 className="mb-0 fw-medium">Startline Games</h4> 
                            <div className="d-inline-flex justify-content-center align-items-center p-2 rounded-circle">
                                <button 
                                        className="btn btn-dark btn-sm fw-bold px-xxl-4" 
                                        type="button"
                                        onClick={handleStartlineGamesClick}>
                                        Play
                                </button>
                            </div>
                        </div>
                    </div> */}


                    {/* End: Startline Games Card */}

                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h3 className="mb-0">Main Games</h3>
                    </div>

                    {/* Start: Game List */}
                    <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-4">
                        {games.map((game) => (
                            <div className="col" key={game.id}>
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between align-items-center p-3">
                                        <div>
                                            <h3 className="fw-medium">{game.title}</h3>
                                            <h4 className={`fw-bold fs-12 mb-0 ${game.open_status === '1' || game.close_status === '1' ? 'text-success' : 'text-danger'}`}>
                                                {game.open_status === '1' || game.close_status === '1' ? 'Game is Running' : 'Game is Closed for Today'}
                                            </h4>
                                            <p className="mb-0">Result: {game.result}</p>
                                        </div>
                                        
                                        {(game.open_status === '1' || game.close_status === '1') && (
                                            <div className="d-inline-flex justify-content-center align-items-center p-2 rounded-circle">
                                                <button 
                                                    className="btn btn-dark btn-sm fw-bold px-xxl-4" 
                                                    type="button"
                                                    onClick={() => handlePlayClick(game)}>
                                                    Play
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* End: Game List */}
                </div>
                <Footer />
            </main>
        </div>
    );
}
