import React, { useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitHelper from '../../../helper/DigitHelper';
import BidInputField from '../components/BidInputField';
import DigitSelector from '../components/DigitSelector';

const DoublePanna = ({ handleAddBid }) => {
    const [selectedDigit, setSelectedDigit] = useState(DigitHelper.getDigits()[0]); // Default to the first digit
    const [matchingNumbers, setMatchingNumbers] = useState([]);
    const [amounts, setAmounts] = useState(Array(10).fill('')); // Assuming max 10 matching numbers

    useEffect(() => {
        // Update matching numbers when selectedDigit changes
        const updatedNumbers = getMatchingThreeDigitNumbers(selectedDigit);
        setMatchingNumbers(updatedNumbers);
        setAmounts(Array(updatedNumbers.length).fill('')); // Reset amounts on digit change
    }, [selectedDigit]);

    const handleDigitSelect = (digit) => {
        setSelectedDigit(digit);
    };

    // Function to get three-digit numbers based on the selected digit
    const getMatchingThreeDigitNumbers = (digit) => {
        const threeDigitNumbers = DigitHelper.getDoublePatti();
        return threeDigitNumbers.filter(number => {
            // Calculate the sum of the digits of the number
            const sumOfDigits = String(number)
                .split('')
                .reduce((acc, curr) => acc + parseInt(curr), 0);
    
            // Get the last digit of the sum
            const lastDigitOfSum = sumOfDigits % 10;
    
            return lastDigitOfSum === digit;
        });
    };
    

    const handleAmountChange = (index, amount) => {
        const updatedAmounts = [...amounts];
        updatedAmounts[index] = amount;
        setAmounts(updatedAmounts);
    };

    const handleAddSinglePannaBid = () => {
        const newBids = matchingNumbers.map((number, index) => (
            amounts[index] ? { digit: number, amount: parseInt(amounts[index]) } : null
        )).filter(bid => bid); // Filter out null values

        handleAddBid(newBids);
        setAmounts(Array(matchingNumbers.length).fill('')); // Clear inputs after adding
    };

    return (
        <div>
            {/* Digit selection */}
            <DigitSelector
                digits={DigitHelper.getDigits()}
                selectedDigit={selectedDigit}
                onSelectDigit={handleDigitSelect}
            />

            {/* Display matching three-digit numbers and amount inputs */}
            <Row className="mb-3 ms-1">
                {matchingNumbers.map((number, index) => (
                    <BidInputField
                        key={number}
                        index={index}
                        digit={number}
                        amount={amounts[index]}
                        handleAmountChange={handleAmountChange}
                        inputHeight='40px' // You can set this based on your design preference
                    />
                ))}
            </Row>

            {/* Add button */}
            <Button className="w-100" style={{ backgroundColor: 'var(--primary-color)', color: 'white' }} onClick={handleAddSinglePannaBid}>
                Add
            </Button>
        </div>
    );
};

export default DoublePanna;
