import React from 'react';
import { Box, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

const ContactButtons = () => {
    const whatsappNumber = "+917015447014"; // Replace with your WhatsApp number
    const callNumber = "+917015447014"; // Replace with your call number

    return (
        <Box display="flex" justifyContent="space-between" width="100%" paddingRight="10px" marginTop="20px">
            <Box 
                display="flex" 
                alignItems="center" 
                className="contact-button"
                onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')} // WhatsApp link
            >
                <WhatsAppIcon style={{ fontSize: 30, marginRight: 8 }} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {whatsappNumber}
                </Typography>
            </Box>

            <Box 
                display="flex" 
                alignItems="center" 
                className="contact-button"
                onClick={() => window.open(`tel:${callNumber}`, '_self')} // Call link
            >
                <CallIcon style={{ fontSize: 30, marginRight: 8 }} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {callNumber}
                </Typography>
            </Box>
        </Box>
    );
};

export default ContactButtons;
