import React, { useState } from 'react';
import { TextField, CardContent, Typography } from '@mui/material';
import ApiService from '../apis/ApiService'; // Adjust the import according to your file structure
import Navigation from '../Navigation';
import Header from '../Header';
import { Card } from 'react-bootstrap';
import LoadingSpinner from '../common/LoadingSpinner';
import SubmitButton from '../common/SubmitButton';
import { toast, ToastContainer } from 'react-toastify';

const WithdrawFund = () => {
    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Start loading

        const requestData = {
            amount,
            remarks,
        };

        try {
            const response = await ApiService.submitWithdrawalRequest(requestData);
            // Show success message
            if (response.status) {
                toast.success(response.message);
                // Clear form
                setAmount('');
                setRemarks('');
            } else {
                toast.error(response.message || 'Failed to submit fund request.');
            }
        } catch (error) {
            // Handle error (e.g., show an error message)
            console.error('Error submitting withdrawal request:', error);
            toast.error("An error occurred while submitting the request.");
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div>
            <Navigation />
            <ToastContainer /> 
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Withdraw Fund
                            </Typography>

                            <TextField
                                label="Amount"
                                variant="outlined"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Remarks"
                                variant="outlined"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            {loading ? <LoadingSpinner /> : <SubmitButton onClick={handleSubmit} label="Submit" />}
                        </CardContent>
                    </Card>
                </div>
            </main>
        </div>
    );
};

export default WithdrawFund;
