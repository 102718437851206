// Navigation.js
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { useUser } from "./helper/UserProvider";
import LoadingSpinner from "./common/LoadingSpinner";

const Navigation = () => {
    const { user, loading } = useUser(); // Get user data and loading state
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location


    if (loading) {
        return <LoadingSpinner/>; // Show loading state if data is still being fetched
    }

    const handleLogout = () => {
        localStorage.removeItem('token'); // Clear the token from localStorage
        navigate('/login'); // Navigate to the login page
    };

    return (
        <aside id="sidebar" className="left-side-menu pb-4 pb-xl-5">
            <div className="container-fluid h-100">
                
                <div className="d-none d-xl-flex flex-column justify-content-center logo-box px-4">
                    <div className="d-flex align-items-center"> {/* Use flexbox for alignment */}
                        <div
                            className="rounded-circle me-2" // Only keep the rounded-circle class
                            style={{
                                width: "50px",
                                height: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "2px solid var(--primary-color)" // Set border color to primary color
                            }}
                        >
                            <span className="text-light fs-4 fw-bold">
                                {user?.name.charAt(0)}
                            </span>
                        </div>
                        <div className="text-light">
                            <div className="fw-bold">{user?.name}</div>
                            <div>{user?.mobile}</div> 
                        </div>
                    </div>
                </div>

                <div className="h-100" data-simplebar="">
                    <ul className="nav nav-tabs flex-column text-capitalize mx-auto menu-items">
                        <li className="nav-item">
                            <span className="fw-bold text-light d-block p-3">Menu</span>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/home" ? "active" : ""}`} to="/home"> 
                                <span className="d-inline-flex me-2"></span>Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/game-rates" ? "active" : ""}`} to="/game-rates">
                                <span className="d-inline-flex me-2"></span>Game Rates
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/my-bids" ? "active" : ""}`} to="/my-bids">
                                <span className="d-inline-flex me-2"></span>My Bids
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/my-profile" ? "active" : ""}`} to="/my-profile">
                                <span className="d-inline-flex me-2"></span>My Profile
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <span className="fw-bold text-light d-block p-3">History</span>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/transactions" ? "active" : ""}`} to="/transactions">
                                <span className="d-inline-flex me-2"></span>Transaction History
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/deposit-requests" ? "active" : ""}`} to="/deposit-requests">
                                <span className="d-inline-flex me-2"></span>Deposit Requests
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === "/withdrawal-requests" ? "active" : ""}`} to="/withdrawal-requests">
                                <span className="d-inline-flex me-2"></span>Withdrawal Requests
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <span className="fw-bold text-light d-block p-3">My Account</span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                <span className="d-inline-flex me-2"></span>Logout
                            </span>
                        </li>
                    </ul>

                </div>
            </div>
        </aside>
    );
};

export default Navigation;
