import React from 'react';
import { Card, Typography, Select, MenuItem } from '@mui/material';

const BidInputSection = ({ playType, handlePlayTypeChange, showOpen, showClose }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
            <Card 
                variant="outlined" 
                style={{ padding: '8px 10px', borderRadius: '12px', height: '50px', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Typography>{new Date().toLocaleDateString()}</Typography>
            </Card>
            { (showOpen || showClose) && (
                <Card 
                    variant="outlined" 
                    style={{ padding: '8px 10px', borderRadius: '12px', height: '50px', flex: 1, display: 'flex', alignItems: 'center' }}
                >
                    <Select
                        value={playType}
                        onChange={handlePlayTypeChange}
                        displayEmpty
                        fullWidth
                        variant="standard"
                        sx={{
                            padding: '8px 0',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            border: 'none',
                            '&:before, &:after': { border: 'none' },
                            '& .MuiSelect-icon': { top: 'calc(50% - 12px)' },
                        }}
                        renderValue={(selected) => selected || 'Select Play Type'}
                    >
                        {showOpen && (
                            <MenuItem value="open" sx={{ textTransform: 'capitalize' }}>Open</MenuItem>
                        )}
                        {showClose && (
                            <MenuItem value="close" sx={{ textTransform: 'capitalize' }}>Close</MenuItem>
                        )}
                    </Select>
                </Card>
            )}
        </div>
    );
};

export default BidInputSection;
