import React, { useState } from 'react';
import { Button, Row, Col, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitHelper from '../../../helper/DigitHelper';
import DigitSelector from '../components/DigitSelector';

const BulkJodi = ({ handleAddBid }) => {
    const [selectedDigit, setSelectedDigit] = useState(DigitHelper.getDigits()[0]); // Default to the first digit
    const [amount, setAmount] = useState('');

    const handleDigitSelect = (digit) => {
        setSelectedDigit(digit);
    };

    const handleAddBulkJodiBid = () => {
        if (amount) {
            // Generate two-digit numbers starting with the selected digit
            const jodiNumbers = Array.from({ length: 10 }, (_, i) => 
                selectedDigit === 0 ? `0${i}` : `${selectedDigit}${i}`
            );

            // Create bids for each generated jodi number with the entered amount
            const newBids = jodiNumbers.map((jodi) => ({
                digit: jodi,
                amount: parseInt(amount)
            }));

            // Pass the generated bids to handleAddBid
            handleAddBid(newBids);

            // Clear the amount input after adding bids
            setAmount('');
        }
    };

    return (
        <div>
            {/* Digit selection */}
            <DigitSelector
                digits={DigitHelper.getDigits()}
                selectedDigit={selectedDigit}
                onSelectDigit={handleDigitSelect}
            />

            {/* Single amount input */}
            <Row className="mb-3 mt-3">
                <Col>
                    <FormControl
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter Amount"
                    />
                </Col>
            </Row>

            {/* Add button */}
            <Button className="w-100" style={{ backgroundColor: 'var(--primary-color)', color: 'white' }} onClick={handleAddBulkJodiBid}>
                Add
            </Button>
        </div>
    );
};

export default BulkJodi;
