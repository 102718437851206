import axios from 'axios';
import config from "../config";

class ApiService {
    constructor() {
        this.baseUrl = config.apiBaseUrl;
    }

    // Login function
    async login(mobile, password) {
        try {
            const response = await axios.post(`${this.baseUrl}/login`, { mobile, password });
            return response.data;
        } catch (error) {
            console.error("Error logging in:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async register(name, mobile, email, password, confirm_password) {
        try {
            const response = await axios.post(`${this.baseUrl}/register`, { name, mobile, email, password, confirm_password });
            return response.data;
        } catch (error) {
            console.error("Error registering:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getGames() {
        try {
            const token = localStorage.getItem('token');  // Retrieve token from local storage
            const response = await axios.post(`${this.baseUrl}/get_main_games`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`  // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching games:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getStarlineGames() {
        try {
            const token = localStorage.getItem('token');  // Retrieve token from local storage
            const response = await axios.post(`${this.baseUrl}/get_starline_games`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`  // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching games:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    
    // Add Bids function
    async addBids(gameId, bidDate, totalAmount, bids, playType) {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const bidsData = bids.map(bid => ({
                game_type: bid.game_type,
                play_type: playType,
                digit: bid.digit,
                amount: bid.amount,
            }));

            console.log(JSON.stringify(bidsData));

            const response = await axios.post(`${this.baseUrl}/add_bids_main`, {
                game_id: gameId,
                bid_date: bidDate,
                amount: totalAmount,
                bids: JSON.stringify(bidsData),
                play_type: playType,
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error adding bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    // Add Bids function
    async check_game_availability(gameId, bidDate,gameType) {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage

            const response = await axios.post(`${this.baseUrl}/check_game_availability`, {
                game_id: gameId,
                bid_date: bidDate,
                game_type: gameType
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error adding bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getBidsMain() {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.get(`${this.baseUrl}/get_bids_main`, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getTransactions() {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.get(`${this.baseUrl}/get_transactions`, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getDepositRequests() {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.get(`${this.baseUrl}/get_fund_requests`, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getWithdrawalRequests() {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.get(`${this.baseUrl}/get_withdrawal_requests`, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getNotifications() {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.get(`${this.baseUrl}/get_notifications`, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }
    async getMyProfile() {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.get(`${this.baseUrl}/get_profile`, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async updateProfile(name, email) {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage

            const response = await axios.post(`${this.baseUrl}/update_profile`, {
                name: name,
                email: email,
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error adding bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async getGameRates() {
        try {
            const response = await axios.get(`${this.baseUrl}/geme_rates`, {});
            return response.data;
        } catch (error) {
            console.error("Error fetching bids:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async submitFundRequest(formData) {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.post(`${this.baseUrl}/submit_fund_request`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important for file uploads
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error submitting fund request:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }

    async submitWithdrawalRequest(requestData) {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await axios.post(`${this.baseUrl}/submit_withdrawal_request`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}` // Add Authorization header
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error submitting withdrawal request:", error);
            throw error.response ? error.response.data : { status: false, message: "Network Error" };
        }
    }
    
}

const apiServiceInstance = new ApiService();
export default apiServiceInstance;
