import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../apis/ApiService';
import Navigation from '../Navigation';
import Header from '../Header';
import LoadingSpinner from '../common/LoadingSpinner';

const DepositRequests = () => {
    const [depositRequests, setDepositRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDepositRequests = async () => {
            setLoading(true);

            try {
                const response = await apiServiceInstance.getDepositRequests(); 
                setDepositRequests(response.data);
            } catch (err) {
                // Handle error if needed
            } finally {
                setLoading(false);
            }
        };

        fetchDepositRequests();
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                {loading && <LoadingSpinner />}
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2>Deposit Requests</h2>
                    {depositRequests.length === 0 ? (
                        <div>No deposit requests found.</div>
                    ) : (
                        <div className="row">
                            {depositRequests.map((request) => (
                                <div className="col-md-4 mb-4" key={request.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Request ID: {request.id}</h5>
                                            <p className="card-text">
                                                <strong>Amount:</strong> {request.amount}<br />
                                                <strong>Transaction ID:</strong> {request.transaction_id || 'N/A'}<br />
                                                <strong>Deposit Type:</strong> {request.deposit_type}<br />
                                                <strong>Remarks:</strong> {request.remarks || 'N/A'}<br />
                                                <strong>Status:</strong> {request.status === "1" ? "Approved" : "Pending"}<br />
                                                <strong>Request Date:</strong> {new Date(request.created_at).toLocaleString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default DepositRequests;
