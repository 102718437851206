import './App.css';
import Login from './pages/Login';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import Home from './pages/Home';
import GameType from './pages/maingame/GameType';
import PlaceBid from './pages/maingame/PlaceBid';
import Signup from './pages/auth/Signup';
import BidHistoryMain from './pages/maingame/BidHistoryMain';
import TransactionHistory from './pages/TransactionHistory';
import DepositRequests from './pages/DepositRequests';
import WithdrawalRequests from './pages/WithdrawalRequests';
import MyProfile from './pages/MyProfile';
import { UserProvider } from './helper/UserProvider';
import Notifications from './pages/Notifications';
import GameRates from './pages/GameRates';
import AddFund from './pages/AddFund';
import WithdrawFund from './pages/WithdrawFund';

//import { UserProvider, useUser } from '../UserContext'; // Import UserProvider

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token'); // Check for token
  return token ? children : <Navigate to="/" />;
}

function App() {
  return (
      <UserProvider>
          <Router>
              <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/game-rates" element={<GameRates />} />
                  <Route 
                      path="/home" 
                      element={
                          <PrivateRoute>
                              <Home />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/game-type" 
                      element={
                          <PrivateRoute>
                              <GameType />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/place-bid" 
                      element={
                          <PrivateRoute>
                              <PlaceBid />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/my-bids" 
                      element={
                          <PrivateRoute>
                              <BidHistoryMain />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/transactions" 
                      element={
                          <PrivateRoute>
                              <TransactionHistory />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/deposit-requests" 
                      element={
                          <PrivateRoute>
                              <DepositRequests />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/add-fund" 
                      element={
                          <PrivateRoute>
                              <AddFund />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/withdrawal-requests" 
                      element={
                          <PrivateRoute>
                              <WithdrawalRequests />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/withdraw-fund" 
                      element={
                          <PrivateRoute>
                              <WithdrawFund />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/my-profile" 
                      element={
                          <PrivateRoute>
                              <MyProfile />
                          </PrivateRoute>
                      } 
                  />
                  <Route 
                      path="/notifications" 
                      element={
                          <PrivateRoute>
                              <Notifications />
                          </PrivateRoute>
                      } 
                  />
                  {/* Catch all unmatched paths and redirect to Home */}
                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>
          </Router>
      </UserProvider>
  );
}

export default App;
