import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import Navigation from '../../Navigation';
import { IconButton, Typography, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiService from '../../apis/ApiService';
import SingleDigit from './games/SingleDigit';
import BidInputSection from './components/BidInputSection';
import { Button, Card } from 'react-bootstrap';
import JodiDigit from './games/JodiDigit';
import SinglePanna from './games/SinglePanna';
import DoublePanna from './games/DoublePanna';
import TripplePanna from './games/TripplePanna';
import BulkJodi from './games/BulkJodi';
import Games from '../../constants/Games';
import { ToastContainer, toast } from 'react-toastify';

export default function PlaceBid() {
    const location = useLocation();
    const navigate = useNavigate();
    const { gameType, game } = location.state || {};

   // console.log(gameType);
    const [playType, setPlayType] = useState('');
    const [bidList, setBidList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [gameAvailability, setGameAvailability] = useState(null);

    useEffect(() => {
        if (!game || !gameType) {
            navigate('/home');
            return;
        }

        const checkAvailability = async () => {
            const bidDate = new Date().toISOString().split('T')[0];
            try {
                const response = await ApiService.check_game_availability(game.id, bidDate,gameType.slug);
                if (response.status) {
                    setGameAvailability(response.data);
                } else {
                    alert(response.message);
                    navigate('/home');
                }
            } catch (error) {
                alert("Error checking game availability: " + error.message);
                navigate('/home');
            }
        };

        checkAvailability();
    }, [game, gameType, navigate]);

    const game_type = gameType?.slug;
    const game_type_id = gameType?.game_type_id;
    
    useEffect(() => {
        if (gameAvailability) {
            // Set playType based on game availability
            if (gameAvailability.open_status) {
                setPlayType('open');
            } else if (gameAvailability.close_status) {
                setPlayType('close');
            }
        }
    }, [gameAvailability]);

    const handlePlayTypeChange = (event) => setPlayType(event.target.value);

    const handleAddBid = (newBids) => {
        const formattedBids = newBids.map((bid) => ({
            ...bid,
            game_type,
            play_type: playType,
        }));

        const updatedBidList = bidList.concat(formattedBids);
        setBidList(updatedBidList);
        setTotalAmount(updatedBidList.reduce((sum, bid) => sum + bid.amount, 0));
    };
    
    const handleDeleteBid = (index) => {
        const newBidList = bidList.filter((_, i) => i !== index);
        setBidList(newBidList);
        setTotalAmount(newBidList.reduce((sum, bid) => sum + bid.amount, 0));
    };

    const handleSubmit = async () => {
        const bidDate = new Date().toISOString().split('T')[0];
        setLoading(true);

        try {
            const response = await ApiService.addBids(game.id, bidDate, totalAmount, bidList, playType);
            if (response.status) {
                setBidList([]);
                setTotalAmount(0);
                toast.success(response.message || "Bids submitted!");
            } else {
                toast.error("Something went wrong. Please try again later!");
            }
        } catch (error) {
            alert("Error submitting bids: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    {!gameAvailability ? (
                        <CircularProgress style={{ margin: '20px 0' }} />
                    ) : (
                        <>
                            {gameAvailability.open_status || gameAvailability.close_status ? (
                                <div>
                                    <h3>{game?.title} - {gameType.name}</h3>
                                    <BidInputSection
                                        playType={playType}
                                        handlePlayTypeChange={handlePlayTypeChange}
                                        showOpen={gameAvailability.open_status}
                                        showClose={gameAvailability.close_status}
                                    />

                                    {game_type_id === Games.SINGLE_DIGIT && (
                                        <SingleDigit 
                                            handleAddBid={handleAddBid} />
                                    )}

                                    {game_type_id === Games.JODI && (
                                        <JodiDigit
                                            handleAddBid={handleAddBid} />
                                    )}

                                    {game_type_id === Games.SINGLE_PANEL && (
                                        <SinglePanna
                                            handleAddBid={handleAddBid} />
                                    )}
                                    {game_type_id === Games.DOUBLE_PANEL && (
                                        <DoublePanna
                                            handleAddBid={handleAddBid} />
                                    )}

                                    {game_type_id === Games.TRIPLE_PANEL && (
                                        <TripplePanna
                                            handleAddBid={handleAddBid} />
                                    )}

                                    {game_type_id === Games.BULK_JODI && (
                                        <BulkJodi
                                            handleAddBid={handleAddBid} />
                                    )}


                                    {bidList.length > 0 && (
                                        <div>
                                            <Card style={{ marginTop: '20px' }}>
                                                <div style={{ marginTop: '20px' }}>
                                                    {/* <Typography variant="h6">Bid List</Typography> */}
                                                    <table style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Number</th>
                                                                <th>Amount</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bidList.map((bid, index) => (
                                                                <tr key={index}>
                                                                    <td>{bid.digit}</td>
                                                                    <td>{bid.amount}</td>
                                                                    <td>
                                                                        <IconButton onClick={() => handleDeleteBid(index)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                                                        Total Amount: {totalAmount}
                                                    </Typography>
                                                </div>
                                            </Card>
                                            {loading && <CircularProgress style={{ margin: '20px 0' }} />}
                                            <Button
                                                className="w-100"
                                                style={{ marginTop: '20px', backgroundColor: '#4CAF50', color: 'white' }}
                                                onClick={handleSubmit}
                                            >Submit
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                    <Typography variant="h5">This game is closed for today!</Typography>
                                    <Button
                                        style={{ marginTop: '20px', backgroundColor: '#007bff', color: 'white' }}
                                        onClick={() => navigate('/home')}
                                    >
                                        Try another game!
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {/* Toastify Container for Notifications */}
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    draggable
                    theme="colored"
                />
                <Footer />
            </main>
        </div>
    );
}
