import React from 'react';

const DigitSelector = ({ digits, selectedDigit, onSelectDigit }) => {
    return (
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '15px', paddingBottom: '10px' }}>
            <div style={{ display: 'inline-flex', gap: '10px' }}>
                {digits.map((digit) => (
                    <div
                        key={digit}
                        onClick={() => onSelectDigit(digit)}
                        style={{
                            cursor: 'pointer',
                            padding: '10px 15px',
                            borderRadius: '50%',
                            backgroundColor: selectedDigit === digit ? 'var(--primary-color)' : 'lightgray',
                            color: selectedDigit === digit ? 'white' : 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '1.2rem',
                            minWidth: '40px',
                            minHeight: '40px',
                        }}
                    >
                        {digit}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DigitSelector;
