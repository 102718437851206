import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../apis/ApiService';
import Navigation from '../Navigation';
import Header from '../Header';
import LoadingSpinner from '../common/LoadingSpinner';
import { Card } from 'react-bootstrap';

const GameRates = () => {
    const [gameRates, setGameRates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGameRates = async () => {
            setLoading(true);

            try {
                const response = await apiServiceInstance.getGameRates(); // Fetching game rates data
                setGameRates(response.main_game); // Assuming response.main_game contains the game rates list
            } catch (err) {
                // Handle error if needed
            } finally {
                setLoading(false);
            }
        };

        fetchGameRates();
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                {loading && (
                    <LoadingSpinner />
                )}

                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2>Game Rates</h2>
                    {gameRates.length === 0 ? (
                        <div>No game rates found.</div>
                    ) : (
                        <div className="row">
                            {gameRates.map((game) => (
                                <div className="col-md-6 mb-4" key={game.id}>
                                    <Card className='p-3'>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <strong>{game.title}</strong>
                                            <strong>10 Ka {game.price * 10}</strong>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default GameRates;
