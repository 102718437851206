import React from 'react';
import { CircularProgress } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoadingSpinner = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <CircularProgress style={{ margin: '20px 0' }} />
        </div>
    );
};

export default LoadingSpinner;
