import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../apis/ApiService';
import Navigation from '../Navigation';
import Header from '../Header';
import { Button, CircularProgress, TextField, Container, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyProfile = () => {
    const [profileData, setProfileData] = useState({ name: '', email: '', mobile: '' });
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            setLoading(true);
            try {
                const response = await apiServiceInstance.getMyProfile();
                const { name, email, mobile } = response.data;
                setProfileData({ name, email, mobile });
            } catch (err) {
                toast.error("Failed to load profile.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUpdateProfile = async () => {
        setUpdating(true);
        try {
            const { name, email } = profileData;
            const response = await apiServiceInstance.updateProfile(name, email);
            toast.success(response.message || "Profile updated successfully!");
        } catch (err) {
            toast.error("Failed to update profile.");
        } finally {
            setUpdating(false);
        }
    };

    return (
        <div>
            <Navigation />
            <main className="content-page p-xl-4 p-xxl-5">
                <Header />
                <Container maxWidth="sm" style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        My Profile
                    </Typography>

                    {loading ? (
                        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
                    ) : (
                        <>
                            <TextField
                                label="Name"
                                name="name"
                                value={profileData.name}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={profileData.email}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Mobile"
                                name="mobile"
                                value={profileData.mobile}
                                fullWidth
                                margin="normal"
                                InputProps={{ readOnly: true }}
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleUpdateProfile}
                                disabled={updating}
                                style={{ marginTop: '20px' }}
                            >
                                {updating ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                            </Button>
                        </>
                    )}
                </Container>

                {/* Toastify Container for Notifications */}
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    draggable
                    theme="colored"
                />
            </main>
        </div>
    );
};

export default MyProfile;
