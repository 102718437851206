// src/Login.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Metropolis.css';
import '../assets/css/styles.css';
import logoImg from '../assets/img/logo.png';
import ApiService from '../apis/ApiService'; // Import the API service
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');

        try {
            const result = await ApiService.login(mobile, password);

            if (result.status) {
                // Store the token in local storage
                localStorage.setItem('token', result.token);
                // Redirect to another page or update state as necessary
                  navigate('/home');
            } else {
                setErrorMessage(result.message);
            }
        } catch (error) {
            setErrorMessage(error.message || "Login failed");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-dark min-vh-100 d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="mx-auto p-4" style={{ maxWidth: '578px' }}>
                    <form className="text-center" onSubmit={handleLogin}>
                        <div>
                            <a className="navbar-brand d-inline-block me-0" href="/">
                                <img src={logoImg} alt="Logo" />
                            </a>
                        </div>
                        <h4 className="text-uppercase text-light my-4 my-xl-4">Login to your account</h4>
                        <div>
                            <input
                                className="form-control input-secondary"
                                type="text"
                                name="mobile"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                required
                            />
                        </div>
                        <div className="my-3 my-xl-4">
                            <input
                                className="form-control input-secondary"
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {errorMessage && <div className="text-danger">{errorMessage}</div>}
                        <div className="my-3 my-xl-4">
                            <button className="btn btn-warning text-uppercase fw-bold d-block w-100" type="submit" disabled={isLoading}>
                                {isLoading ? "Loading..." : "Login"}
                            </button>
                        </div>
                    </form>
                    <div className="text-center text-over-line">
                        <span className="fs-6 bg-dark px-3">OR</span>
                    </div>
                    {/* <p className="text-center text-light my-3 my-xl-4">Continue with</p> */}
                    
                    <p className="text-light d-flex fs-5 justify-content-center align-items-center mb-0 mt-3 mt-xl-4">
                        Don't have an account?&nbsp;
                        <Link className="btn btn-link fs-5 link-warning p-0 text-decoration-none" to="/signup">Sign up</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
