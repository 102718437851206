import React, { useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitHelper from '../../../helper/DigitHelper';
import BidInputField from '../components/BidInputField';
import DigitSelector from '../components/DigitSelector';

const JodiDigit = ({ handleAddBid }) => {
    const [selectedDigit, setSelectedDigit] = useState(DigitHelper.getDigits()[0]); // Default to the first digit
    const [jodiAmounts, setJodiAmounts] = useState(Array(10).fill(''));

    useEffect(() => {
        setJodiAmounts(Array(10).fill('')); // Reset amounts on initial load or digit change
    }, [selectedDigit]);

    const handleDigitSelect = (digit) => {
        setSelectedDigit(digit);
    };

    const handleJodiAmountChange = (index, amount) => {
        const updatedAmounts = [...jodiAmounts];
        updatedAmounts[index] = amount;
        setJodiAmounts(updatedAmounts);
    };

    const getJodiNumbers = () => {
        return Array.from({ length: 10 }, (_, i) =>
            selectedDigit === 0 ? `0${i}` : `${selectedDigit}${i}`
        );
    };

    const handleAddJodiBid = () => {
        const jodiNumbers = getJodiNumbers();
        const newBids = jodiAmounts.map((amount, index) => (
            amount ? { digit: jodiNumbers[index], amount: parseInt(amount) } : null
        )).filter(bid => bid);

        handleAddBid(newBids);
        setJodiAmounts(Array(10).fill('')); // Clear inputs after adding
    };

    return (
        <div>
            {/* Digit selection */}    
            <DigitSelector
                digits={DigitHelper.getDigits()}
                selectedDigit={selectedDigit}
                onSelectDigit={handleDigitSelect}
            />

            {/* Display selected jodi numbers and amount inputs */}
            <Row className="mb-3 ms-1">
                {getJodiNumbers().map((jodi, index) => (
                    <BidInputField
                        key={jodi}
                        index={index}
                        digit={jodi}
                        amount={jodiAmounts[index]}
                        handleAmountChange={handleJodiAmountChange}
                        inputHeight='40px' // Height of the input box
                    />
                ))}
            </Row>

            {/* Add button */}
            <Button className="w-100" style={{ backgroundColor: 'var(--primary-color)', color: 'white' }} onClick={handleAddJodiBid}>
                Add
            </Button>
        </div>
    );
};

export default JodiDigit;
