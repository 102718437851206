// src/components/SubmitButton.js
import React from 'react';
import { Button } from '@mui/material';

const SubmitButton = ({ onClick, label }) => {
    return (
        <Button
            variant="contained"
            onClick={onClick}
            style={{ marginTop: '20px' }}
        >
            {label} {/* Use the dynamic label here */}
        </Button>
    );
};

export default SubmitButton;
