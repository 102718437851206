import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitHelper from '../../../helper/DigitHelper';
import BidInputField from '../components/BidInputField';

const SingleDigit = ({ handleAddBid }) => {
    const inputHeight = '40px';
    const digits = DigitHelper.getDigits();
    const [amounts, setAmounts] = useState(Array(digits.length).fill(''));

    const handleAmountChange = (index, value) => {
        const updatedAmounts = [...amounts];
        updatedAmounts[index] = value;
        setAmounts(updatedAmounts);
    };

    const handleAddSingleBid = () => {
        const newBids = amounts.map((amount, index) => (
            amount ? { digit: digits[index], amount: parseInt(amount) } : null
        )).filter(bid => bid);

        handleAddBid(newBids);
        setAmounts(Array(digits.length).fill('')); // Clear inputs after adding
    };

    return (
        <div>
            <Row className="mb-3 ms-1">
                {digits.map((digit, index) => (
                    <BidInputField
                        key={index}
                        index={index}
                        digit={digit}
                        amount={amounts[index]}
                        handleAmountChange={handleAmountChange}
                        inputHeight={inputHeight}
                    />
                ))}
            </Row>
            <Button className="w-100" style={{ backgroundColor: 'var(--primary-color)', color: 'white' }}  onClick={handleAddSingleBid}>
                Add
            </Button>
        </div>
    );
};

export default SingleDigit;
