// src/Header.jsx

import React from 'react'; // Import useContext
import { showNavbar } from './assets/js/main'; // Adjust the path as necessary
import './assets/css/Metropolis.css';
import './assets/css/styles.css';
import logoImg from './assets/img/logo_header.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'; 
import { useUser } from "./helper/UserProvider";
import { Link } from 'react-router-dom';
const Header = () => {
   // const { user } = useContext(useUser); // Access user data from context
    const { user } = useUser();
    return (
        <>
            <div id="sidebar-overlay" className="sidebar-overlay" onClick={showNavbar}></div>
            <header className="navbar-custom p-3 p-xl-0 position-relative">
                <div className="row">
                    <div className="col-6 col-xl-8 align-self-center align-self-xl-start">
                        <div className="d-flex">
                            <button className="navbar-toggler d-xl-none p-0 me-3" data-bs-toggle="collapse" onClick={showNavbar}>
                                <span className="visually-hidden">Toggle navigation</span>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <img src={logoImg} alt="Logo" className="logo-img ms-2" style={{ height: '40px' }} />
                        </div>
                    </div>
                    <div className="col-6 col-xl-4 text-end align-self-center align-self-xl-start">
                        <div className="d-flex justify-content-end align-items-center">
                            {/* Balance display with wallet icon */}
                            <Link to="/transactions" className="btn bg-wallet text-dark d-flex align-items-center p-2 me-2" aria-label="Balance" style={{ height: '40px' }}>
                                <AccountBalanceWalletIcon className="me-1" style={{ fontSize: '1rem', color: 'black' }} />
                                <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    {user?.balance ? `₹${user.balance}` : '₹0.00'}
                                </span>
                            </Link>

                            {/* Notification icon */}
                            <Link to="/notifications" className="btn bg-wallet d-inline-flex justify-content-center align-items-center btn-svg p-0 me-2" href="/" aria-label="Notifications" style={{ height: '40px', width: '40px' }}>
                                <NotificationsIcon style={{ fontSize: '1.2rem', color: 'black' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
